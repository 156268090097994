:root {
  --joinchat-ico: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M3.516 3.516c4.686-4.686 12.284-4.686 16.97 0 4.686 4.686 4.686 12.283 0 16.97a12.004 12.004 0 0 1-13.754 2.299l-5.814.735a.392.392 0 0 1-.438-.44l.748-5.788A12.002 12.002 0 0 1 3.517 3.517zm3.61 17.043.3.158a9.846 9.846 0 0 0 11.534-1.758c3.843-3.843 3.843-10.074 0-13.918-3.843-3.843-10.075-3.843-13.918 0a9.846 9.846 0 0 0-1.747 11.554l.16.303-.51 3.942a.196.196 0 0 0 .219.22l3.961-.501zm6.534-7.003-.933 1.164a9.843 9.843 0 0 1-3.497-3.495l1.166-.933a.792.792 0 0 0 .23-.94L9.561 6.96a.793.793 0 0 0-.924-.445 1291.6 1291.6 0 0 0-2.023.524.797.797 0 0 0-.588.88 11.754 11.754 0 0 0 10.005 10.005.797.797 0 0 0 .88-.587l.525-2.023a.793.793 0 0 0-.445-.923L14.6 13.327a.792.792 0 0 0-.94.23z'/%3E%3C/svg%3E");
  --joinchat-font: -apple-system, blinkmacsystemfont, 'Segoe UI', roboto,
    oxygen-sans, ubuntu, cantarell, 'Helvetica Neue', sans-serif;
  --s: 8rem;
}

.joinchat__button {
  position: fixed;
  z-index: 999999;
  bottom: 3rem;
  right: 3rem;
  height: var(--s);
  width: var(--s);
  background: #25d366;
  border-radius: calc(var(--s) / 2);
  box-shadow: 1px 6px 24px 0 rgba(7, 94, 84, 0.24);
  cursor: pointer;
  transition: background-color 0.2s linear;
  display: flex;
}

/*.joinchat *,
.joinchat :after,
.joinchat :before {
  box-sizing: border-box;
}*/

.joinchat__button a {
  display: block;
  width: 100%;
  height: 100%;
}

.joinchat__button a .wpp_container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.icon {
  filter: invert(100%);
  width: 65%;
}

.joinchat__button:hover {
  transform: scale(1.1);
  opacity: 0.9;
  filter: brightness(105%);
  transition: all ease-in-out 0.3s;
}
